import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inquiry",
    component: () =>
      import(/* webpackChunkName: "Skip" */ "../view/Inquiry.vue")
  },
  {
    path: "/certificates/:code",
    name: "Certificates",
    component: () =>
      import(/* webpackChunkName: "Skip" */ "../view/Certificates.vue")
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () =>
      import(/* webpackChunkName: "Skip" */ "../view/Gallery.vue")
  },
  {
    path: "/artworks",
    name: "ArtWorks",
    component: () =>
      import(/* webpackChunkName: "Skip" */ "../view/ArtWorks.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
