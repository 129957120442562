import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasChange: false,
    activeTab: "inquiry",
  },
  mutations: {
    CHANGE_TAB(state, tabName) {
      state.activeTab = tabName;
    },
  },
  actions: {
    changeTab({ commit }, tabName) {
      commit("CHANGE_TAB", tabName);
    },
  },
  modules: {},
  plugins: [createPersistedState()]
});
