import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vant/lib/index.css";
import "../src/utils/rem";
import store from "./store";
import AMapLoader from "@amap/amap-jsapi-loader";
Vue.use(AMapLoader);
import {
  AddressEdit,
  AddressList,
  Area,
  SwipeCell,
  Button,
  Cell,
  CellGroup,
  Checkbox,
  CountDown,
  CheckboxGroup,
  Col,
  Collapse,
  TreeSelect,
  CollapseItem,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  Empty,
  Field,
  Form,
  Icon,
  Image as VanImage,
  Overlay,
  ImagePreview,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Radio,
  RadioGroup,
  Row,
  Search,
  Sku,
  Stepper,
  Sticky,
  Swipe,
  SwipeItem,
  Switch,
  Tab,
  Tabbar,
  DatetimePicker,
  TabbarItem,
  Tabs,
  Tag,
  Calendar,
  Steps,
  Step,
  NoticeBar,
  Badge,
  Card,
  PasswordInput,
  NumberKeyboard,
  Popover
} from "vant";

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
Vue.use(Col);
Vue.use(Row);
Vue.use(Collapse);
Vue.use(CountDown);
Vue.use(CollapseItem);
Vue.use(TreeSelect);
Vue.use(SwipeCell);
Vue.use(Sku);
Vue.use(DatetimePicker);
Vue.use(CheckboxGroup);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Stepper);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Form);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(VanImage);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(ImagePreview);
Vue.use(Picker);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Cell);
Vue.use(Checkbox);
Vue.use(Stepper);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Empty);
Vue.use(Switch);
Vue.use(NavBar);
Vue.use(CellGroup);
Vue.use(Sticky);
Vue.use(PullRefresh);
Vue.use(Calendar);
Vue.use(Steps);
Vue.use(Step);
Vue.use(NoticeBar);
Vue.use(Badge);
Vue.use(Card);
Vue.use(SwipeCell);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Popover);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
